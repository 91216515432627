:root {
  --primary-color: #729e7a;
  --primary-light-color: #a9d7ae;
  --secondary-color: #404041;
  --secondary-light-color: #525252;
}

.welcome-carousel {
  height: 2133px;

  .carousel-inner {
    top: -5px;
  }
}

.bg-pattern {
  background-image: url(/assets/img/kowerk-pattern.jpg) !important;
  background-size: cover !important;
}

.header {
  .logo {
    img {
      width: 700px !important;
    }
  }

  h3,
  .h3 {
    font-size: 100px !important;
  }
}

.scan-qr-container {
  flex-direction: row-reverse;

  .buttons-arrow {
    padding-left: 0 !important;
    margin-top: 130px;

    img {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
}

.call-tenant-container {
  .container-fluid {
    padding-right: 0;
  }
}

/*** CALL & HELP PAGE ***/
.tenant-container {
  .card-body {
    font-size: 60px;
  }
}
.tenant-list-container {
  
  padding-right: 100px;
  margin-right: 175px;
  height: 1620px;

  .card-body {
    font-size: 42px;
  }
  .row {
    overflow-y: auto;
    
    scroll-snap-type: y mandatory;
    
    .col {
      scroll-snap-align: start;
      scroll-snap-stop: always;
    }
    
   
  }
  .row::-webkit-scrollbar {
    display: none;
  }


  
}
.calculator-buttons {
  .btn-outline-secondary:hover {
    color: var(--secondary-color);
    border-color: var(--secondary-color);
    background: #fff;
  }
  .btn-outline-secondary:active {
    border-color: var(--secondary-light-color);
    color: #FFFFFF;
    background: var(--secondary-light-color);
  }
}
.tenant-list-container::-webkit-scrollbar {
  width: 142px;
}
.search-btn {
  position: absolute;
  right: 85px;
  width: 100px;
  height: 100px;
  border: 7px solid #607786;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 310px;
  img {
    width: 50px;
    filter: brightness(0) saturate(100%) invert(43%) sepia(32%) saturate(283%) hue-rotate(160deg) brightness(97%) contrast(89%);
  }
}
.hosts-container {
  position: relative;
}
.code-block {
  .close {
    right: 85px;
  }
  h1 {
    margin-right: 275px;
  }
}

.scroll-btn {
  position: absolute;
  right: 85px;
  width: 100px;
  height: 100px;
  cursor: pointer;
  background-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  filter: brightness(0) saturate(100%) invert(43%) sepia(32%) saturate(283%) hue-rotate(160deg) brightness(97%) contrast(89%);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  border: 7px solid #607786;
}

.top-scroll {
  top: 0;
  background-image: url('/assets/kowerk/icons/arrow-up.svg');
}
.bottom-scroll {
  top: auto;
  bottom: 300px;
  background-image: url('/assets/kowerk/icons/arrow-down.svg');
}

/*.tenant-list-container::-webkit-scrollbar-button:start:decrement,
.hosts-block::-webkit-scrollbar-button:end:increment {
  display: block;
}

.tenant-list-container::-webkit-scrollbar-button:vertical:start:increment,
.tenant-list-container::-webkit-scrollbar-button:vertical:end:decrement {
  display: none;
}

.tenant-list-container::-webkit-scrollbar-track:vertical {
  background: transparent;
}

.tenant-list-container::-webkit-scrollbar-track-piece {
  display: none;
}

.tenant-list-container::-webkit-scrollbar-thumb:vertical {
  display: none;
}

.tenant-list-container::-webkit-scrollbar-button:start:decrement,
.tenant-list-container::-webkit-scrollbar-button:end:increment {
  display: block;
}

.tenant-list-container::-webkit-scrollbar-button:start:increment,
.tenant-list-container::-webkit-scrollbar-button:end:decrement {
  display: none;
}*/

/*.tenant-list-container::-webkit-scrollbar-button:end:increment {
  background-image: url('/assets/kowerk/icons/circle-arrow-down.svg');
  width: 100px;
  height: 100px;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 60%;
}

.tenant-list-container::-webkit-scrollbar-button:start:decrement {
  background-image: url('/assets/kowerk/icons/circle-arrow-up.svg');
  width: 100px;
  height: 100px;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 60%;
}*/

.call-code-block {
  min-height: 2450px;
}
/** MULTI CONTACT MODALS **/
.call-modal-box {
  width: 100%;
  padding-top: 200px;
  padding-bottom: 200px;
  position: absolute;
  top: 1060px;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95) !important;
  z-index: 6;
  bottom: 285px;
  .modal-button{
    padding: 60px 0;
  }
  &.touchpad-modal-open {
    .numpad-keys {
      padding-top: 0;
    }
    .call-modal-btn,
    .call-modal-img-box {
      display: none !important;
    }
    .call-page-modal-button,
    .call-modal-text-box,
    .tenant-name {
      height: 0;
      opacity: 0;
      visibility: hidden;
      margin: 0;
    }
    .numpad-btn img {
      filter: brightness(0) saturate(100%) invert(42%) sepia(13%) saturate(2297%) hue-rotate(100deg) brightness(95%) contrast(88%);
    }
  }

  &.solid {
    background-color: rgba(255, 255, 255, 0.95) !important;
  }

  &.big-call-modal {
    padding-top: 150px;
    padding-bottom: 150px;
    .modal-button {
      padding-bottom: 0;
    }
  }

  h2 {
    font-size: 120px;
    text-align: center;
    margin-top: 90px;
    padding: 0 200px;
  }

  .exit-btn {
    position: absolute;
    top: 90px;
    right: 85px;
    z-index: 10;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    transition: all 0.3s;

    &:hover {
      background-color: #d3d3d3;
      overflow: hidden;
    }

    a,
    button {
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      padding: 0;
      background: none;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
  .numpad-keys {
    //background-color: #BDCEC666;
    padding-top: 100px;
    width: 1300px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 80px;
  }
  .call-modal-img-box {
    width: 520px;
    height: 260px;
    border: 5px solid black;
    border-radius: 1rem;
    background-color: white;
    margin: 0 auto;
    padding: 50px;
    transition: all 0.3s;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }

  .call-modal-text-box {
    font-size: 64px;
    line-height: 1.2;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    &.wider {
      max-width: 1500px;
    }
  }
  
  .call-modal-icons {
    //background-color: white;  //rgba(255, 255, 255, 0.95)
    
    display: flex;
    justify-content: center;
    gap: 500px;
    padding-bottom: 100px;
    padding-top: 40px;

    .modal-red-box,
    .modal-green-box {
      display: block;
      border-radius: 50%;
      margin: 0 !important;
      width: 270px !important;
      height: 270px !important;
      border: none;
      transition: all 0.3s;
      

      img {
        width: 150px;
        padding: 10px !important;
      }
    }

    .modal-red-box {
      background-color: #dc3545;
    }

    .modal-green-box {
      background-color: #198754;
    }
  }

  .numpad-btn {
    img {
      width: 180px;
    }
    button {
      border: none;
      background-color: transparent;
    }
    .numpad-text {
        font-size: 70px;
    }
    .numpad-text-open {
        display: none;
    }
    .numpad-text-active .numpad-text-hidden{
        display: none;
    }
    .numpad-text-active .numpad-text-open{
        display: block;
        color: #198754;
    }
  }

  .call-cta-box {
    background-color: white;
    padding-top: 90px;
    padding-left: 200px;
    padding-right: 200px;
    //overflow-y: auto;
    //padding-bottom: 300px;
    position: relative;
    .top-scroll {
      top: 188px;
    }
    .bottom-scroll {
      bottom: 100px;
    }
    ul {
      padding: 0;
      margin: 0;
      height: 1220px;
      overflow-y: auto;
      padding-right: 70px;
      scroll-snap-type: y mandatory;

      li {
        list-style-type: none;
        border-bottom: 1.5px solid rgba(0, 0, 0, 0.2);
        scroll-snap-align: start;
        scroll-snap-stop: always;

        .list-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }

        .cta-text-box {
          font-weight: 800;
          font-size: 64px;
          line-height: 1.27;
        }

        .cta-btn-box {
          display: flex;
          align-items: center;
          padding: 67px 0;
          background-color: transparent;
          gap: 50px;
          border: none;

          .green-box {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #198754;
            border-radius: 50%;
            width: 170px;
            height: 170px;
            border: none;
            transition: all 0.3s;

            img {
              object-fit: contain;
              width: 82px;
              height: 82px;
            }
          }

          p {
            font-weight: 600;
            font-size: 50px;
            margin: 0;
            line-height: 1.27;
          }
        }
      }
    }
    ul::-webkit-scrollbar {
      display: none;
    }
  }
}

.green-box-sm {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: none;
  transition: all 0.3s;

  img {
    object-fit: contain;
    width: 20px;
    height: 20px;
  }
}

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.75);
    overflow-x: hidden;
    transition: 0.5s;

    &.success-modal {
      .modal-dialog {
        
          max-width: 100% !important;
      }
      .modal-content {
          padding: 220px 100px;
      }
      .exit-btn {
          position: absolute;
          right: 85px;
          top: 100px;
          background-color: transparent;
          border: none;
          img {
              width: 100px;
          }
      }
      .check-modal-text-box {
        img {
          height: auto;
          width: auto;
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
        h1,h2,h3,h4,h5 {
          margin-bottom: 32px;
          margin-top: 0;
          line-height: 1.2;
        }
        h1 {
          font-size: 160px; 
          font-weight: 700;
        }
        h2 {
          font-size: 138px;
          font-weight: 600;
        }
        h3 {
          font-size: 106px;
          font-weight: 600;
        }

        h4 {
          font-size: 95px;
          font-weight: 500;
        }
        h5 {
          font-size: 90px;
          font-weight: 500;
        }
        p {
          font-size: 80px;
          margin-bottom: 32px; 
        }
        ul,ol {
          padding-left: 120px;
        }
        li {
          margin-bottom: 24px;
        }
        a {
          text-decoration: underline;
        }
      }
    }
}
.padlock {


    &.verified {
        top: 180px;
        width: 350px;
        right: 250px;
        z-index: 5;
        img {
            width: 250px;
        }
    }
}


/********* WAREHOUSE XS *********/
.warehouse-xs {

  &.numpad-open h5.title,
  &.keyboard-open h5.title {
      padding: 0;
      height: 0;
  }
  &.success-modal {
      .modal-content {
          padding: 50px 30px;
      }
      .exit-btn {
        top: -20px;
        right: 20px;
        img {
          width: 28px;
        }
      }
      .check-modal-text-box {
          img {
          height: auto;
          width: auto;
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
          }
          h1,h2,h3,h4,h5 {
          margin-bottom: 16px;
          margin-top: 0;
          line-height: 1.2;
          }
          h1 {
          font-size: 36px; 
          font-weight: 700;
          }
          h2 {
          font-size: 28px;
          font-weight: 600;
          }
          h3 {
          font-size: 25px;
          font-weight: 600;
          }

          h4 {
          font-size: 22px;
          font-weight: 500;
          }
          h5 {
          font-size: 20px;
          font-weight: 500;
          }
          p {
          font-size: 16px;
          margin-bottom: 16px; 
          }
          ul,ol {
          padding-left: 32px;
          }
          li {
          font-size: 16px;
          margin-bottom: 16px;
          }
      }
    }
  .search-btn {
    top: -100px;
    right: 4px;
    width: 28px;
    height: 28px;
    border: 2px solid #607786;
    padding: 0;
    
    img {
      width: 15px;
      filter: brightness(0) saturate(100%) invert(43%) sepia(32%) saturate(283%) hue-rotate(160deg) brightness(97%) contrast(89%);
    }
  }
.scroll-btn {
  position: absolute;
  right: 4px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  background-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  filter: brightness(0) saturate(100%) invert(43%) sepia(32%) saturate(283%) hue-rotate(160deg) brightness(97%) contrast(89%);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  border: 2px solid #607786;
}

.top-scroll {
  top: 0;
  background-image: url('/assets/kowerk/icons/arrow-up.svg');
}
.bottom-scroll {
  top: auto;
  bottom: 50px;
  background-image: url('/assets/kowerk/icons/arrow-down.svg');
}

&.big-call-modal {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    position: absolute;
    top: 285px;
    left: 0;
    .call-modal-img-box {
      width: 170px !important;
      height: 70px !important;
    }
  .search-btn,
  .scroll-btn {
    right: 65px;
  }
}

  &.container-fluid {
    padding: 0 65px !important;
  }

  .container-fluid {
    padding: 0 65px !important;
  }

  .btn {
    font-size: 22px !important;
    padding: 13px 47px !important;
    margin: 0;
    border: none;
    background: var(--primary-color);
    color: #fff;
  }

  h1,
  .h1 {
    font-size: 50px;
  }

  h2,
  .h2 {
    font-size: 34px !important;
  }

  h3,
  .h3 {
    font-size: 32px !important;
  }

  .container-fluid {
    padding: 0 40px;
  }

  &.container-fluid {
    padding: 0 40px;
  }

  &.header {
    padding-top: 45px !important;

    .logo {
      a {
        display: flex !important;
      }

      img {
        margin-top: 5px;
        height: 55px;
        width: auto !important;
      }

      h3 {
        margin-top: 14px !important;
        font-size: 25px !important;
      }
    }

    .clock-container {
      margin-top: 0 !important;

      .time {
        font-size: 45px !important;

      }

      .date {
        font-size: 25px !important;
      }
    }
  }

  &.welcome {
    h1 {
      padding-top: 85px !important;
      padding-bottom: 10px !important;
    }

    .btn {
      margin-bottom: 35px !important;
    }
  }

  &.overlay h1 {
    font-size: 48px;
  }

  &.overlay .overlay-content {
    padding: 40px !important;
    top: 50% !important;
    transform: translateY(-50%);
    margin-top: 0;
  }

  .mt-25 {
    margin-top: 25px;
  }

  &.overlay .loading .dot {
    background: #FFFFFF !important;
  }

  &.overlay .overlay-content h5 {
    margin: 50px 0 !important;
    font-size: 32px !important;
  }

  &.overlay .overlay-content span {
    font-size: 48px;
  }

  &.overlay .overlay-content img {
    height: 160px !important;
  }

  .lang .btn-icon {
    height: 25px !important;
    margin-bottom: 1px !important;
  }

  .lang button {
    width: 40px !important;
    height: 40px;
    font-size: 22px !important;
    line-height: 1 !important;
    font-weight: 600 !important;
  }

  .lang li {
    margin-bottom: 15px !important;
  }

  .welcome-carousel {
    height: 583px;

    .carousel-inner {
      top: -1px;
    }
  }

  &.footer {
    bottom: 30px !important;
  }

  .language-menu {
    right: 65px !important;

    button {
      margin-left: 10px !important;
    }
  }

  h5.title {
    font-size: 27px;
    padding-top: 90px;
    padding-bottom: 25px;
  }

  .code-block h1 {
    font-size: 55px !important;
  }
  
  &.code-block {
    padding-top: 65px;
    min-height: 688px;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;
    
    h1 {
      margin-bottom: 0;
      padding-right: 0;
    }

    .close {
      top: 25px;
      right: 69px;
    }

    p {
      font-size: 20px;
      margin-bottom: 25px;
      margin-top: 25px;
      margin-left: 35px;
    }

    .bg-gray {
      padding: 22px 300px 22px 100px;
      margin: 0;
      margin-left: -65px;
      width: 130%;
    }

    .close img {
      width: 28px !important;
    }
  }
  &.code-block.keyboard-open {
    min-height: 570px;
    padding-top: 15px;
    padding-bottom: 15px;
    h1 {
      display: none;
    }
  }
  
  .card .logo {
    height: 65px;
  }

  .tenant-container {
    margin-top: 40px !important;
  }

  .tenant-list-container {
    //overflow-y: auto;
    //height: 390px;
    height: 420px;
    padding-right: 100px;
    margin-right: 0px;
    

    &.help {
      height: 220px;
    }
    .row {
      max-height: 450px;
    }
    .row.g-5 {
      --bs-gutter-x: 15px;
      --bs-gutter-y: 10px;
    }
    .col-4 {
      scroll-snap-align: start;
      scroll-snap-stop: always;
    }
    .logo {
      border: 2px solid #000000;
      padding: 5px;
      border-radius: 5px !important;
    }

    .card-body {
      font-size: 18px;
      padding: 5px;
    }

    &::-webkit-scrollbar-button:end:increment {
      width: 30px;
      height: 30px;
    }

    &::-webkit-scrollbar-button:start:decrement {
      width: 30px;
      height: 30px;
    }

  }

  &.call-modal-box {
    &.touchpad-modal-open {
      .call-modal-text-box {
        margin: 0;
      }
    }
    width: 100%;
    padding-top: 55px;
    padding-bottom: 55px;
    position: absolute;
    top: 285px;
    left: 0;
    background-color: hsla(0, 0%, 100%, .95) !important;
    z-index: 6;
    bottom: 44px;
    transition: all .2s;
    &.numpad-modal {
      top: 148px;
    }
    &.solid {
      background-color: rgba(243, 243, 243, 1) !important;
    }

    h2 {
      text-align: center;
      margin-top: 16px;
    }

    .big-call-modal {
      padding-top: 20px;
    }

    .exit-btn {
      position: absolute;
      top: 20px;
      right: 65px;
      z-index: 10;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      transition: all 0.3s;

      a,
      button {
        display: block;
        width: 100%;
        height: 100%;
        border: none;
        padding: 0;
        background: none;
        font-size: 18px;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }

      &:hover {
        background-color: #d3d3d3;
        overflow: hidden;
      }
    }
    .numpad-btn {
      margin-top: 20px;
      img {
        width: 60px;
      }
      .numpad-text {
        font-size: 20px;
      }
    }    
    .call-modal-icons {
      padding-bottom: 0;
      padding-top: 15px;
    }
    .call-modal-img-box {
      width: 200px;
      height: 80px;
      padding: 10px 20px;
      border: 2px solid black;
      border-radius: 5px;
      background-color: white;
      margin: 0 auto;
      transition: all 0.3s;

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
        vertical-align: top;
      }
    }

    .call-modal-text-box {
      background-color: white;
      font-size: 20px;
      line-height: 1.2;
      max-width: 400px;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      &.wider {
        max-width: 500px;
      }
    }

    .call-cta-box {
      font-size: 24px;
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 160px;
      overflow-y: auto;
      height: 358px;

      .top-scroll {
        top: 45px;
      }
      .bottom-scroll {
        bottom: 45px;
      }
      ul {
        padding: 0;
        margin: 0;
        height: 324px;

        li {
          list-style-type: none;
          border-bottom: 1.5px solid rgba(0, 0, 0, 0.2);

          .list-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .cta-text-box {
              font-weight: 800;
              font-size: 20px;
              line-height: 1.27;
            }

            .cta-btn-box {
              display: flex;
              align-items: center;
              padding: 15px 0;
              background-color: transparent;
              gap: 10px;
              border: none;

              p {
                font-weight: 600;
                font-size: 16px;
                margin: 0;
                line-height: 2;
              }
            }
          }
        }
      }
    }

    .call-modal-img-box a {
      display: block;
      width: auto;
      height: 100%;
    }

    .wide-modal-text {
      max-width: 500px;
      margin-bottom: 10px;
    }

    .call-modal-icons {
      display: flex;
      justify-content: center;
      gap: 140px;
    }

    .modal-red-box,
    .modal-green-box {
      display: block;
      border-radius: 50%;
      margin: 0 !important;
      width: 80px !important;
      height: 80px !important;
      border: none;
      transition: all 0.3s;
    }

    .modal-red-box {
      background-color: rgba(220, 53, 69, 1);
    }

    .modal-green-box {
      background-color: #198754;
    }

    .modal-red-box img,
    .modal-green-box img {
      padding: 0 !important;
      width: 40px;
      height: 40px;
      display: flex;
      object-fit: cover;
      margin: 0 auto;
    }
  }

  .padlock.verified {
    top: 45px;
    right: 130px;
    width: 130px;

    img {
      width: 100%;
    }
  }

}
